export default function compileInvalidFormField(element) {
  const inputGroup = element.closest('.input-group')
  const formGroup = element.closest('.form-group')

  function setInputGroupInvalid(isInvalid) {
    inputGroup?.classList?.toggle('is-invalid', isInvalid)
  }

  function unsetOtherFormGroupFieldsInvalid() {
    if (formGroup) {
      Array.from(formGroup.querySelectorAll('.is-invalid')).forEach((element) => {
        element.classList.remove('is-invalid')
      })
      formGroup.querySelector('.invalid-feedback.d-block')?.classList?.remove('d-block')
    }
  }

  function unsetInvalid() {
    element.classList.remove('is-invalid')
    unsetOtherFormGroupFieldsInvalid()
    setInputGroupInvalid(false)
  }

  setInputGroupInvalid(true)
  element.addEventListener('input', unsetInvalid, { once: true })
}
