import environment from '../util/environment'

up.compiler('.billing-item', (element) => {

  const amountField = element.querySelector('[name*="[amount]"]')
  const destroyField = element.querySelector('[name*="_destroy"][type="checkbox"]')

  amountField?.addEventListener('input', onInputAmount)
  destroyField?.addEventListener('input', onInputDestroy)

  function onInputAmount() {
    updateTotal()
  }

  async function onInputDestroy({ target }) {
    target.checked &&= confirm(`${target.labels[0]?.title || 'Löschen'}?`)
    if (target.checked) await fadeOut()
    element.hidden = target.checked
    updateTotal()
  }

  async function fadeOut() {
    if (environment.isTest) return Promise.resolve()

    const { opacity, height, paddingBlock, marginBlock } = getComputedStyle(element)
    const keyframes = [
      { opacity, height, paddingBlock, marginBlock },
      { opacity: 0, height, paddingBlock, marginBlock },
      { opacity: 0, height: 0, paddingBlock: 0, marginBlock: 0 },
    ]
    await element.animate(keyframes, { duration: 400, easing: 'ease-out' }).finished
  }

  function updateTotal() {
    up.emit('app:total-billing-items-amount:update')
  }

})
